<template>
    <div id="UserBarWraper">
        <div class="container">
            <div class="row">
                <div class="col-4 text-left">
                   {{ this.$root.usrdta.user_email }}
                </div>
                <div class="col-4 text-center">
                  <router-link :to="{ name: 'panel-deal-detail', params: {key: '0'} }">
                    <i class="text-success">
                      <font-awesome-icon icon="fa-solid fa-add" />
                    </i> Add Deal
                  </router-link>
                  <span style="margin:0 15px;">|</span>
                  <router-link :to="{ name: 'panel-deal-list' }">
                    <i class="text-primary">
                      <font-awesome-icon icon="fa-solid fa-list" />
                    </i> List Deals
                  </router-link>
                </div>
                <div class="col-4 text-right">
                    <router-link to="/panel/login">
                        <i class="text-danger">
                            <font-awesome-icon icon="fa-solid fa-power-off" />
                        </i> Logout
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Page-UserBar",
        data() {
            return {
            }
        },
    };
</script>

<style>
    #UserBarWraper{
        position: relative;
        background:#232323;
        color:#fff;
        font-weight: 550;
        padding:10px 0;
        box-shadow: 3px 3px 8px 0px #00000096;
        z-index: 99999;
    }
</style>