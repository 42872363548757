<template>
    <div id="app-content" v-bind:class="{'panel': panel}">
        <UserBar v-if="logged_in && !panel"></UserBar>
        <TopBar v-if="!panel"></TopBar>
        <div id="contentContainer">
            <router-view></router-view>
        </div>
        <Footer v-if="!panel"></Footer>

    </div>
</template>

<script>
import TopBar from "./components/TopBar";
import Footer from "./components/Footer";
import UserBar from "./components/UserBar";

export default {
    name: 'App',
    components: {
        UserBar,
        TopBar,
        Footer
    },
    data() {
        return {
            panel : false,
            apiUrl: "https://api.top10-deals.de/",
        }
    },
    created: function () {
        this.panel = window.location.pathname.includes("/panel");
        this.checkUser();
        this.setOptions();
    },
    watch: {
        $route() {
            this.checkUser();
            this.panel = window.location.pathname.includes("/panel");
        },
    },
    methods: {
        setOptions: function () {
            if (window.location.hostname == "localhost") {
                this.apiUrl = "http://api.top10-deals.local/";
            } else {
                this.apiUrl = "https://api.top10-deals.de/";
            }
        },
        checkUser: function () {
            if(
                this.panel
                && !this.logged_in
                && !window.location.pathname.includes("/login")
            ){
              this.$router.push("/");
            }
            this.timetable = localStorage.getItem("rtslclusr");
            //TODO: Zeitprüfung
            //TODO: loginverifizieren
            if (this.timetable == null) {
                this.logged_in = false;
            } else {
                this.fgrprnt = localStorage.getItem("fgrprnt");
                this.usrdta = JSON.parse(localStorage.getItem("usrdta"));
                this.logged_in = true;
            }
        }
    }
};
</script>

<style>
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #0f1927;
    margin-top: 0px;

}
#app-content.panel{
  background: #313235;
}
</style>
