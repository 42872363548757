import { createApp } from 'vue'
import router from  "./router"
import App from './App.vue'

import * as bootstrap from "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/assets/css/main.css";
import "@/assets/css/color_skins.css";
import "@/assets/fonts/material-design-iconic-font.min.css";
import "@/assets/css/custom.css";
import "bootstrap/dist/js/bootstrap.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faBell,faBars,faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { faHome,faAdd,faTrash} from "@fortawesome/free-solid-svg-icons";
import { faPencil,faList,faLock} from "@fortawesome/free-solid-svg-icons";

import VueSweetalert2  from "vue-sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css'

const app = createApp(App, {
    data() {
        return {};
    },
});

library.add(faBell,faBars,faPowerOff);
library.add(faHome,faAdd,faTrash);
library.add(faPencil,faList,faLock);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(bootstrap);
app.use(router);
app.use(VueSweetalert2);
app.mount("#app");

