import * as VueRouter from "vue-router";
import { createWebHistory } from "vue-router";


const routes = [
    {
        path: "/",
        name: "home",
        component: () =>
            import("../views/Home")
    },
    {
        path: "/impressum",
        name: "impressum",
        component: () =>
            import("../views/static_pages/Impressum")
    },
    {
        path: "/datenschutz",
        name: "datenschutz",
        component: () =>
            import("../views/static_pages/Datenschutz")
    },
    {
        path: "/deals/:key",
        name: "deals",
        component: () =>
            import("../views/Deals")
    },
    {
        path: "/newsletter/anmelden",
        name: "newsletter-soi",
        component: () =>
            import("../views/NewsletterSoi.vue")
    },
    {
        path: "/newsletter/:key",
        name: "newsletter-doi",
        component: () =>
            import("../views/NewsletterDoi.vue")
    },
    {
        path: "/panel/login",
        name: "panel-login",
        component: () =>
            import("../views/panel/Login")
    },
    {
        path: "/panel/deal",
        name: "panel-deal-list",
        component: () =>
            import("../views/panel/DealList.vue")
    },
    {
        path: "/panel/deal/:key",
        name: "panel-deal-detail",
        component: () =>
            import("../views/panel/DealDetail.vue")
    },
];

const router = VueRouter.createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
