<template>
    <div id="FooterWraper">
        <div class="container">
            <div class="row">
                <div class="col text-left">
                    <router-link to="/">
                        Top10-Deals.de<br/>
                        &copy; {{JAHR}} Inc. Alle Rechte vorbehalten.
                    </router-link>
                </div>
                <div class="col-sm text-right footernav">
                    <router-link to="/impressum">
                        Impressum
                    </router-link>
                    <span> | </span>
                    <router-link to="/datenschutz">
                        Datenschutz
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Page-Footer",
        data: function () {
            return {
                backgroundUrl: 'Hello'
            }
        },
    };
</script>

<style>
    #FooterWraper a{
        color:#0f1927;
    }
    #FooterWraper{
        background-color: #efefef;
        color:#0f1927;
        padding: 20px 0;
        background-position: bottom center;
        background-size: cover;
    }
    #FooterWraper .footernav {
        text-align: right;
    }
    #FooterWraper .footernav span{
        color:#0f1927;
        margin-left:5px;
        margin-right:5px;
    }
    @media screen and (max-width: 576px) {
        .footernav{
            text-align: center!important;
            margin-top:10px;
        }
    }
</style>