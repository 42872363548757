<template>
    <div>
        <div id="TopBarWrapper">
            <div class="container">
                <div class="row">
                    <div class="col-sm-3 text-left col-6">
                        <router-link to="/">
                            <img alt="Top10-Deals.de" title="Top10-Deals.de" src="@/assets/img/Top10Deals_logo_bunt-invers.png" />
                        </router-link>
                    </div>
                    <div class="col-sm-9 col-6 navbar-expand-sm text-right ">
                        <div class="d-lg-none NavMobileToggle" >
                          <a  data-bs-toggle="offcanvas" href="#offcanvasNav" role="button" aria-controls="offcanvasNav">
                            <span style="color:#fff;cursor:pointer" >
                                <font-awesome-icon icon="fa-solid fa-bars" />
                            </span>
                          </a>
                        </div>
                        <div class="d-none d-lg-block">
                          <span class="navItem">
                            <router-link to="/">
                                Home
                            </router-link>
                          </span>
                          <span class="navItem"  @mouseover="showSubnav = true" @mouseleave="showSubnav = false">
                              <span>
                                  Deal Days
                              </span>
                              <span class="NavBox" v-if="showSubnav" >
                                  <router-link :to="{ name: 'deals', params: {key: 'montag'} }">
                                  <div class="NavBoxItem">
                                      <strong>Montag</strong>
                                      <small>Unterhaltungselektronik</small>
                                  </div>
                                  </router-link>
                                  <router-link :to="{ name: 'deals', params: {key: 'dienstag'} }">
                                  <div class="NavBoxItem">
                                      <strong>Dienstag</strong>
                                      <small>Küche, Haushalt & Wohnen</small>
                                  </div>
                                  </router-link>
                                  <router-link :to="{ name: 'deals', params: {key: 'mittwoch'} }">
                                  <div class="NavBoxItem">
                                      <strong>Mittwoch</strong>
                                      <small>Geschenkideen</small>
                                  </div>
                                  </router-link>
                                  <router-link :to="{ name: 'deals', params: {key: 'donnerstag'} }">
                                  <div class="NavBoxItem">
                                      <strong>Donnerstag</strong>
                                      <small>Baby & Spielwaren</small>
                                  </div>
                                  </router-link>
                                  <router-link :to="{ name: 'deals', params: {key: 'freitag'} }">
                                  <div class="NavBoxItem">
                                      <strong>Freitag</strong>
                                      <small>Mode & Schuhe</small>
                                  </div>
                              </router-link>
                              <router-link :to="{ name: 'deals', params: {key: 'samstag'} }">
                                  <div class="NavBoxItem">
                                      <strong>Samstag</strong>
                                      <small>Freizeit & Musik</small>
                                  </div>
                                </router-link>
                                <router-link :to="{ name: 'deals', params: {key: 'sonntag'} }">
                                  <div class="NavBoxItem">
                                      <strong>Sonntag</strong>
                                      <small>Tarifvergleich</small>
                                  </div>
                               </router-link>
                              </span>
                          </span>
                          <span class="navItem navHighlight">
                            <router-link :to="{ name: 'deals', params: {key: 'heute'} }">
                                Deal des Tages
                            </router-link>
                          </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <div ref="offcanvas" class="offcanvas offcanvas-start" style="z-index: 99999" tabindex="-1" id="offcanvasNav" aria-labelledby="offcanvasNavLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel"></h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <div style="margin-bottom: 15px">
            <router-link to="/">
              <img  alt="Top10-Deals.de" title="Top10-Deals.de" src="@/assets/img/Top10Deals_logo_header.png" />
            </router-link>
          </div>
          <div class="text-left">
            <router-link to="/">
              <div class="MobileNavBoxItem">
                <strong>Startseite</strong>
              </div>
            </router-link>
            <router-link :to="{ name: 'deals', params: {key: 'montag'} }">
              <div class="MobileNavBoxItem">
                <strong>Montag</strong>
                <small>Unterhaltungselektronik</small>
              </div>
            </router-link>
            <router-link :to="{ name: 'deals', params: {key: 'dienstag'} }">
              <div class="MobileNavBoxItem">
                <strong>Dienstag</strong>
                <small>Küche, Haushalt & Wohnen</small>
              </div>
            </router-link>
            <router-link :to="{ name: 'deals', params: {key: 'mittwoch'} }">
              <div class="MobileNavBoxItem">
                <strong>Mittwoch</strong>
                <small>Geschenkideen</small>
              </div>
            </router-link>
            <router-link :to="{ name: 'deals', params: {key: 'donnerstag'} }">
              <div class="MobileNavBoxItem">
                <strong>Donnerstag</strong>
                <small>Baby & Spielwaren</small>
              </div>
            </router-link>
            <router-link :to="{ name: 'deals', params: {key: 'freitag'} }">
              <div class="MobileNavBoxItem">
                <strong>Freitag</strong>
                <small>Mode & Schuhe</small>
              </div>
            </router-link>
            <router-link :to="{ name: 'deals', params: {key: 'samstag'} }">
              <div class="MobileNavBoxItem">
                <strong>Samstag</strong>
                <small>Freizeit & Musik</small>
              </div>
            </router-link>
            <router-link :to="{ name: 'deals', params: {key: 'sonntag'} }">
              <div class="MobileNavBoxItem">
                <strong>Sonntag</strong>
                <small>Tarifvergleich</small>
              </div>
            </router-link>
            <router-link :to="{ name: 'deals', params: {key: 'heute'} }">
              <strong>Deal des Tages</strong>
            </router-link>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        name: "Page-TopBar",
        data() {
            return {
                showSubnav: false
            }
        },
        created: function () {
        },
        methods: {
        },
        watch: {
            $route() {
                var navCanvas = document.getElementById('offcanvasNav');
                navCanvas.classList.remove("show");
                document.querySelectorAll('.offcanvas-backdrop').forEach(e => e.remove());
                this.showSubnav = false;
            },
        },
    };
</script>

<style>
    #TopBarWrapper{
        z-index:9999;
        position: relative;
        border-bottom: 2px solid #00000033;
        background: #0000005e;
        box-shadow: 3px 3px 8px 0 #00000096;
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
        height: 105px;
        padding:35px 0;
    }
    #TopBarWrapper img{
        max-width: 156px;
        width:auto;
    }
    .NavMobileToggle{
        font-size: 25px;
    }
    #TopBarWrapper ul{
        padding: 0;
        float:right;
    }
    #TopBarWrapper ul li{
        cursor:pointer;
        list-style-type: none;
        float:left;
        margin-left:25px;
    }
    #offcanvasNav .offcanvas-body{
      color:#373737;
    }
    #offcanvasNav .offcanvas-body strong{
      display: block;
    }
    #offcanvasNav .offcanvas-body .MobileNavBoxItem{
      margin-bottom: 12px;
    }
    .navItem{
        margin-left:50px;
        position: relative;
        height: 35px;
        display: inline-block;
        cursor: pointer;
        color:#fff
    }
    .navItem a,.navItem span{
        text-transform: uppercase;
        color:#fff;
        font-weight: bold;
        font-size: 18px;
        padding:10px;
        border-radius: 10px;
    }
    .navItem.navHighlight a{
        background:#00b6ed;
        text-transform: uppercase;
        color:#fff;
        font-weight: bold;
        font-size: 18px
    }
    .text-right{text-align: right}
    .NavBox{
        position: absolute;
        left: -50px;
        top: 35px;
        width: 250px;
        z-index:99999;
        background: #fffffff7;
        box-shadow: 3px 3px 8px 0 #00000096;
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
        border-radius: 12px;
        padding:0 !important;
    }
    .NavBox a{
        padding:0;
    }
    .NavBoxItem{
        cursor:pointer;
        text-align: left;
        border-top:1px solid #e1e1e1;
        z-index:99999;
        position: relative;
        padding:15px 15px 5px;
    }
    .NavBoxItem:hover{
        background:#373737;
        color:#fff !important;
    }
    .NavBoxItem:hover small{
        background:#373737;
        color:#fff !important;
    }
    .NavBoxItem strong{
        font-size: 16px;
        line-height: 8px;
    }
    .NavBoxItem small{
        font-size: 12px;
        line-height: 8px;
    }
    .NavBoxItem:after{
        background-image: url('~@/assets/img/arrow_right.png');
        background-size: 10px 20px;
        position: absolute;
        right: 10px;
        top: 17px;
        width: 10px;
        height: 20px;
        content:"";
    }
    .NavBoxItem:first-of-type {
        text-align: left;
        border-top:none;
    }
    .NavBoxItem Strong{
        color:#00b6ed;
        display: block;
        z-index:99999;
    }
    .NavBoxItem small{
        color:#000;
        z-index:99999;
    }
</style>